<template>
	<div class="history_box">
		<div style="font-size: 0.16rem;color: #666;">
			{{$store.state.history.father_name}}  /  {{$store.state.history.name}}
		</div>
		<!-- <div style="font-weight: bold;font-size: 0.22rem;">
			{{$store.state.history.name}}
		</div> -->
	</div>
</template>

<script>
	export default{
		data(){
			return{
				
			}
		},
		methods:{
			
		}
	}
</script>

<style lang="scss" scoped>
	.history_box{
		width: 100%;
		// height: 45px;
		background-color: #fff;
		font-size: 14px;
		display: flex;
		flex-direction: column;
		padding: 0 20px;
		color: rgba(0,0,0,.65);
		justify-content: space-between;
		padding: 10px 15px;
		box-sizing: border-box;
	}
</style>