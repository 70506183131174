<template>
	<div v-if="dialogVisible">
		<el-dialog title="修改个人信息" :visible.sync="dialogVisible" width="50%"
			:before-close="closebox">
			<div class="addbox">
				<div class="add_cont">
					<div class="baseinfobox">
						<div style="width: 100%;padding-left: 2%;">
							<el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="110px"
								label-position="left" class="demo-ruleForm">
								<div class="from_div">
									<el-form-item label="用户姓名" prop="name">
										<el-input size="small" v-model="ruleForm.name" class="btx"
											style="width:2.2rem"></el-input>
									</el-form-item>
									<el-form-item label="登录名" prop="username">
										<el-input size="small" v-model="ruleForm.username" class="btx"
											style="width:2.2rem"></el-input>
									</el-form-item>
								</div>
								<div class="from_div">
									<el-form-item label="密码">
										<el-input size="small" v-model="ruleForm.password"
											style="width:2.2rem"></el-input>
									</el-form-item>
									<el-form-item label="性别" prop="sex" class="raido">
										<el-radio-group v-model="ruleForm.sex" class="btx" style="width:2.2rem;">
											<el-radio :label="1">男</el-radio>
											<el-radio :label="2">女</el-radio>
										</el-radio-group>
									</el-form-item>
								</div>

								<div class="from_div">
									<el-form-item label="出身年月" prop="birthday">
										<div style="width: 2.2rem;display: flex;">
											<el-date-picker v-model="ruleForm.birthday" type="month" placeholder="选择日期"
												value-format="yyyy-MM">
											</el-date-picker>
										</div>
									</el-form-item>
									<el-form-item label="单位" prop="work_unit">
										<el-input size="small" v-model="ruleForm.work_unit"
											style="width:2.2rem"></el-input>
									</el-form-item>
								</div>
								<div class="from_div">
									<el-form-item label="职务" prop="duties">
										<el-input size="small" v-model="ruleForm.duties" style="width:2.2rem"></el-input>
									</el-form-item>
									<el-form-item label="党派" prop="party_id">
										<el-input size="small" v-model="ruleForm.party_id"
											style="width:2.2rem"></el-input>
									</el-form-item>
								</div>
								<div class="from_div">
									<el-form-item label="界别" prop="circles_id">
										<el-select style="width:2.2rem;" v-model="ruleForm.circles_id" placeholder="请选择">
											<el-option v-for="item in optionjiebie" :key="item.id" :label="item.name"
												:value="item.id">
											</el-option>
										</el-select>
									</el-form-item>
									<el-form-item label="通讯地址" prop="address">
										<el-input size="small" v-model="ruleForm.address" style="width:2.2rem"
											placeholder="请输入"></el-input>
									</el-form-item>
								</div>

								<div class="from_div">
									<el-form-item label="联系电话" prop="phone">
										<el-input size="small" v-model="ruleForm.phone" style="width:2.2rem"></el-input>
									</el-form-item>
								</div>

								<div class="from_div">
									<div style="width: 100%;">
										<el-form-item label="单位/公司介绍" label-width="120px">
											<div>
												<el-radio v-model="infotype" :label="1">上传PDF</el-radio>
												<el-radio v-model="infotype" :label="2">填写链接</el-radio>
											</div>
											<el-upload v-if="infotype==1" class="upload-demo" :action="baseurl+'/api/login/upload'"
												:before-upload="beforeAvatarUpload" :limit="1" :show-file-list="false"
												:on-success="successpdf">
												<el-button size="small" type="primary">点击上传</el-button>
											</el-upload>
											
											<el-input v-if="infotype==2" size="small" v-model="ruleForm.unit_intro"
												placeholder="请输入"></el-input>
										</el-form-item>
									</div>
								</div>

								<div class="from_div" style="margin-top: 10px;">
									<el-form-item label="照片" prop="education_id" style="width: 100%;">
										<div style="display: flex;flex-direction: column;">
											<div class="person-img">
												<img v-show="ruleForm.photo" :src="ruleForm.photo" alt=""
													style="width: 130px;height: 189px;">
											</div>
											<el-upload ref="myupload" class="upload-demo"
												:action="baseurl+'/api/login/upload'" :show-file-list="false"
												:on-success="successfile" :data="{
												 token:$store.state.userinfo.token
											 }">
												<el-button size="small" style="margin-top: 10px;">
													<i class="el-icon-upload2"></i>
													点击上传
												</el-button>
											</el-upload>
										</div>
									</el-form-item>
								</div>
							</el-form>
						</div>



					</div>

					<div class="btnbox">
						<el-button type="primary" size="mini" class="font14" @click="save()">保存</el-button>
						<el-button type="info" size="mini" class="font14" @click="closebox">关闭</el-button>
					</div>
				</div>
			</div>
		</el-dialog>
	</div>

</template>

<script>
	export default {
		data() {
			return {
				baseurl: '',
				input: '',
				dialogVisible: false,
				radio: 1,
				infotype: 2,
				userimg: '',
				options: [], //权限角色
				ruleForm: {
					name: '',
					username: '',
					password: '',
					number: '',
					useradmin: [],
					circles_id: '',
					gz: [],
					qm: [],
					sex: 1,
					idcard: '',
					rytype: 1,
					birthday: '',
					education_id: '',
					nation_id: '',
					organizational_id: '',
					organizational_name: '',
					phone: '',
					unit_intro: '',
					address: '',
					youbian: '810000',
					birthday: '',
					work_unit: '',
					duties: '',
					party_id: '',
				},
				rules: {
					name: [{
						required: true,
						message: ' ',
						trigger: 'blur'
					}],
					username: [{
						required: true,
						message: ' ',
						trigger: 'blur'
					}],
					number: [{
						required: true,
						message: ' ',
						trigger: 'blur'
					}],
				},
				optionxueli: [], //学历
				optionmz: [], //民族
				optionarea: [], //所属地区
				optiondangp: [], //党派
				optionjiebie: [], //界别
				optionsszwh: [], //所属专委会
				optionzxzw: [], //政协职务
				seletedbm: [],


			}
		},
		created() {
			this.baseurl = this.$URL

			//获取角色权限
			this.$post({
				url: '/api/roleadmin/index',
				params: {
					page: 1,
					page_size: 999
				}
			}).then((res) => {
				this.options = res.list
			})
			// this.getoptionxueli()//学历
			// this.getoptionmz()//民族
			// this.getoptionarea()//所属地区
			// this.getoptiondangp()//党派
			this.getoptionjiebie() //界别
			// this.getoptionsszwh()//所属专委会
			// this.getoptionzxzw()//政协职务
		},
		methods: {
			successpdf(response){
				if(response.code==200){
					this.infotype = 2
					this.ruleForm.unit_intro = response.fullurl
					this.$message.success('上传成功！')
				}else{
					this.$message.error('上传失败，请重试！')
				}				
			},
			beforeAvatarUpload(file) {
				console.log(file.type)
				const isPDF = file.type === 'application/pdf';
				const isLt10M = file.size / 1024 / 1024 < 10;

				if (!isPDF) {
					this.$message.error('只能上传PDF文件!');
				}
				if (!isLt10M) {
					this.$message.error('PDF文件大小不能超过 10MB!');
				}
				return isPDF && isLt10M;
			},
			sethuixian() { //设置回显
				let newlist = this.ruleForm.organizational_id.split(',')
				let newlist2 = this.ruleForm.organizational_name.split(',')
				this.seletedbm = []
				newlist.forEach((item, index) => {
					let obj = {
						id: item,
						name: newlist2[index]
					}
					this.seletedbm.push(obj)
				})
			},
			selected(e) {
				this.ruleForm.organizational_name = e.map((item) => {
					return item.name
				}).join(',')
				this.ruleForm.organizational_id = e.map((item) => {
					return item.id
				}).join(',')
			},
			showselectbm() {
				this.$refs.myselectfather.dialogVisible = true
			},
			save(i) {
				this.$refs.ruleForm.validate((valid) => {
					if (valid) {	
						delete this.ruleForm.gz
						delete this.ruleForm.qm
						this.$post({
							url: '/api/user/edit',
							params: this.ruleForm
						}).then((res) => {
							this.$message.success('操作成功');
							this.closebox()
						})
					} else {
						console.log('error submit!!');
						return false;
					}
				})
			},
			closebox() {
				this.resetform()
				this.dialogVisible = false
				this.$parent.getlist()
			},
			resetform() {
				this.ruleForm = {
					name: '',
					username: '',
					password: '',
					number: '',
					useradmin: [],
					circles_id: '',
					gz: [],
					qm: [],
					sex: 1,
					idcard: '',
					rytype: 1,
					birthday: '',
					education_id: '',
					nation_id: '',
					organizational_id: '',
					organizational_name: '',
					phone: '',
					unit_intro: '',
					address: '',
					birthday: '',
					work_unit: '',
					duties: '',
					party_id: '',
					youbian: '810000',
				}
			},
			successfile(response, file, fileList) {
				this.ruleForm.photo = response.fullurl
				this.userimg = response.fullurl
			},
			getoptionxueli() {
				this.$post({
					url: '/api/education/index',
					params: {
						order_by: '',
						sort: '',
						p: 1,
						size: 999
					}
				}).then((res) => {
					this.optionxueli = res.data.list
				})
			},
			getoptionmz() {
				this.$post({
					url: '/api/nation/index',
					params: {
						order_by: '',
						sort: '',
						p: 1,
						size: 999
					}
				}).then((res) => {
					this.optionmz = res.data.list
				})
			},
			getoptionarea() {
				this.$post({
					url: '/api/district/index',
					params: {
						order_by: '',
						sort: '',
						p: 1,
						size: 999
					}
				}).then((res) => {
					this.optionarea = res.data.list
				})
			},
			getoptiondangp() {
				this.$post({
					url: '/api/party/index',
					params: {
						order_by: '',
						sort: '',
						p: 1,
						size: 999
					}
				}).then((res) => {
					this.optiondangp = res.data.list
				})
			},
			getoptionjiebie() {
				this.$post({
					url: '/api/circles/index',
					params: {
						order_by: '',
						sort: '',
						p: 1,
						size: 999
					}
				}).then((res) => {
					this.optionjiebie = res.list
				})
			},
			getoptionsszwh() {
				this.$post({
					url: '/api/affiliated/index',
					params: {
						order_by: '',
						sort: '',
						p: 1,
						size: 999
					}
				}).then((res) => {
					this.optionsszwh = res.data.list
				})
			},
			getoptionzxzw() {
				this.$post({
					url: '/api/political/index',
					params: {
						order_by: '',
						sort: '',
						p: 1,
						size: 999
					}
				}).then((res) => {
					this.optionzxzw = res.data.list
				})
			}
		}
	}
</script>

<style lang="scss" scoped>
	.inputbox {
		padding: 20px;
	}

	.inputline {
		display: flex;
		align-items: center;
		padding: 0 20px;
		margin-bottom: 10px;

		.input_item:nth-child(1) {
			margin-right: 2.2rem;
		}
	}

	.input_item {
		display: flex;
		align-items: center;

		span {
			width: 115px;
		}

		.otherinput {
			width: 4rem;
		}

	}

	.titlename {
		width: 100%;
		margin-top: 45px;
		display: flex;
		align-items: center;
		font-size: 16px;
		font-weight: bold;
		color: #555;

		// background: url(@/assets/img/addnewt2.jpg) left 9px repeat-x;
		.title_icon {
			width: 4px;
			height: 18px;
			background: rgb(178, 117, 124);
			margin-right: 12px;
		}
	}

	.from_div {
		display: flex;
		align-items: center;
		width: 100%;
		justify-content: space-between;
	}

	.addbox {

		width: 100%;
		overflow-y: auto;
		padding: 10px;
	}

	.add_cont {
		width: 100%;
		background-color: #fff;
		padding: 10px;
	}

	.btnbox {
		display: flex;
		align-items: center;
		justify-content: flex-end;
		margin-top: 20px;
		border-top: 1px solid #eee;
		padding-top: 20px;
	}

	.baseinfobox {
		margin-top: 10px;
		width: 100%;
		padding: 10px;
		// background-image: url(@/assets/img/adduser_bg.jpg);
		background-size: 100% 100%;
		background-repeat: no-repeat;
		display: flex;
		align-items: center;
		justify-content: space-between;
		padding-right: 10px;
	}

	.person-img {
		width: 130px;
		height: 189px;
		border-radius: 3px;
		box-shadow: 0px 0px 10px 0px rgba(90, 0, 3, 0.15);
	}

	::v-deep .el-form-item__error {
		padding-top: 0;
	}

	::v-deep .el-form-item {
		margin-bottom: 0px;
	}

	::v-deep .el-form-item__label {
		color: #999;
		font-size: 14px;
	}

	::v-deep .el-form-item.is-required:not(.is-no-asterisk)>.el-form-item__label:before {
		content: '' !important;
	}

	::v-deep .el-input--small .el-input__icon {
		color: #333;
		font-weight: bold;
	}

	::v-deep .el-dialog {
		margin: 0 !important;
	}

	::v-deep .el-dialog__header {
		border-bottom: 1px solid #eee;
	}

	::v-deep .el-dialog__footer {
		border-top: 1px solid #eee;
	}

	::v-deep .el-dialog__body {
		padding-top: 10px;
		// background-image: url(@/assets/img/addtc_bg.jpg);
		background-repeat: no-repeat;
		background-size: 100% 100%;
		padding: 10px;
	}

	::v-deep .el-tabs--border-card {
		height: 100%;
	}

	::v-deep .el-tabs--border-card {
		box-shadow: 0 0 0 #fff;
	}

	::v-deep .el-tabs__header {
		background-color: #fff;
	}

	.btx {
		position: relative;
	}

	.btx:after {
		content: '*';
		color: red;
		left: -12px;
		font-size: 20px;
		top: 0;
		position: absolute;
	}

	::v-deep .raido {
	}
</style>